import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"resposta-multipla-escolha"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","x-small":"","disabled":_vm.respostaQuestionario != null && _vm.respostaQuestionario.length == 0
              ? true
              : false},on:{"click":function($event){return _vm.resetRespostas()}}},[_vm._v(" Limpar respostas ")])],1),(_vm.imagemPergunta)?_c('p',{staticClass:"text-size-90 mt-1"},[_c(VImg,{attrs:{"max-height":"300","max-width":"400","src":_vm.imagemPergunta}})],1):_vm._e()]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"6"}},[_c(VSubheader,[_vm._v("Perguntas")]),_vm._l((_vm.convertPergunta(_vm.alternativas)),function(item){return _c(VCheckbox,{key:item.label,staticClass:"ml-3",style:('white-space: normal'),attrs:{"value":item,"label":item.descricao,"hide-details":"","disabled":_vm.respostaQuestionario != null &&
            _vm.respostaQuestionario.length == _vm.limite_opcoes
              ? true
              : false},on:{"click":function($event){return _vm.atualizaRespostaLocal()}},model:{value:(_vm.respostaQuestionario),callback:function ($$v) {_vm.respostaQuestionario=$$v},expression:"respostaQuestionario"}})})],2),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"6"}},[_c(VList,[_c(VSubheader,[_vm._v("Respostas selecionadas")]),(_vm.respostaQuestionario.length > 0)?_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.respostaQuestionario),function(item,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_vm._v(" "+_vm._s(i + 1)+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.descricao)+" ")])],1)],1)}),1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }