<template>
  <section class="resposta-multipla-escolha">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <div class="d-flex flex-row-reverse">
          <v-btn
            depressed
            color="primary"
            x-small
            @click="resetRespostas()"
            :disabled="
              respostaQuestionario != null && respostaQuestionario.length == 0
                ? true
                : false
            "
          >
            Limpar respostas
          </v-btn>
        </div>
        <p class="text-size-90 mt-1" v-if="imagemPergunta">
          <v-img max-height="300" max-width="400" :src="imagemPergunta"></v-img>
        </p>
      </v-col>

      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-subheader>Perguntas</v-subheader>
          <v-checkbox
            v-for="item in convertPergunta(alternativas)"
            :key="item.label"
            v-model="respostaQuestionario"
            :value="item"
            :label="item.descricao"
            class="ml-3"
            :style="'white-space: normal'"
            hide-details
            :disabled="
              respostaQuestionario != null &&
              respostaQuestionario.length == limite_opcoes
                ? true
                : false
            "
            @click="atualizaRespostaLocal()"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-list>
            <v-subheader>Respostas selecionadas</v-subheader>
            <v-list-item-group
              color="primary"
              v-if="respostaQuestionario.length > 0"
            >
              <v-list-item v-for="(item, i) in respostaQuestionario" :key="i">
                <v-list-item-icon>
                  {{ i + 1 }}
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> {{ item.descricao }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "resposta-checkbox-escolha",
  props: [
    "indexPergunta",
    "textoPergunta",
    "imagemPergunta",
    "alternativas",
    "uuidPergunta",
    "uuidFormulario",
    "categoria",
    "subcategoria",
    "limite_opcoes",
    "codeFormulario",
  ],
  components: {},
  data() {
    return {
      respostaQuestionario: [],
      validateReset: false,
    };
  },
  methods: {
    convertPergunta(item) {
      const array = Array.isArray(item) ? item : JSON.parse(item);
      array.map((item) => {
        item.id = parseInt(item.id);
      });
      return array;
    },
    /**
     * Envia o array de respostas para o componente pai caso as opções estejam completas
     */
    atualizaRespostaLocal() {
      if (this.respostaQuestionario.length < this.limite_opcoes) return null;

      this.defineRespostaAlternativa(this.indexPergunta);
    },
    resetRespostas() {
      if (this.respostaQuestionario.length == 0) return null;
      this.respostaQuestionario.splice(0, this.respostaQuestionario.length);
      this.validateReset = true;

      this.defineRespostaAlternativa(this.indexPergunta);
    },
    defineRespostaAlternativa(indexPergunta) {
      const resposta = this.respostaQuestionario.map((el) => {
        return el.id;
      });
      const data = {
        indexPergunta: indexPergunta,
        uuid_pergunta: this.uuidPergunta, // Uuid do formulario pergunta
        code_formulario: this.codeFormulario,
        uuid_escala_itens: "",
        uuid_alternativa: "",
        opcao: resposta.length > 0 ? resposta : [],
      };

      if (data.opcao.length == 0) data.opcao = [];
      // Atualiza ou Adiciona resposta dentro do array respostas
      if (this.respostas.some((r) => r.indexPergunta === this.indexPergunta)) {
        this.respostas.splice(indexPergunta, 1, data);
      } else {
        this.respostas.push(data);
      }
      // Atualiza data de resposta local do componente pai
      this.$emit("atualiza-resposta-local", data, "checkbox");
    },
    /**
     * [validarArrayRespostas description]
     * @description Pecorre o array de respostas salvas no vuex
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    validarArrayRespostas() {
      if (this.respostas == undefined || this.respostas.length === 0)
        return null;
      this.verificaPerguntas();
    },
    /**
     * [verificaPerguntas description]
     *
     * @description Verifica se o index da pergunta atual está dentro do array de resposta e se o array de resposta atual está vazio, caso estiver
     * o array de resposta recebe as opçoes da pergunta atual, se não ele realiza uma verificação no array de respostas atuais.
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    verificaPerguntas() {
      if (
        this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta) &&
        this.respostaQuestionario.length === 0
      ) {
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );

        if (result.length > 0) {
          this.respostaQuestionario = result[0].opcao.map((opcao) => {
            return this.convertPergunta(this.alternativas).find(
              (alternativa) => {
                if (parseInt(alternativa.id) === parseInt(opcao))
                  return alternativa;
              }
            );
          });
        }
      }
    },
  },
  /**
   * [beforeMount description]
   * @description Responsavel por validar se existe respostas selecionadas no da pergunta atual
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  beforeMount() {
    this.validarArrayRespostas(this.indexPergunta);
  },
  /**
   * [beforeUpdate description]
   * @description Responsavel por verificar as opções selecionadas em tempo real
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  beforeUpdate() {
    this.validarArrayRespostas(this.indexPergunta);
  },
  /**
   * [destroyed description]
   * @description Responsavel por limpar o array de perguntas atuais após o componente ser removido do life cycle
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  destroyed() {
    this.respostaQuestionario = [];
  },
  /**
   * [watch indexPergunta]
   * @description É utilizado quando o componente não é destruido, mas as perguntas são diferentes,
   * ele irá limpar o array de perguntas atuais para seguir o fluxo.
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  watch: {
    indexPergunta: function () {
      // Verificar se existe um objeto resposta em respostas com mesmo indice da pergunta atual
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        // Se existir, respostaQuestionario recebe esse objeto
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );

        if (result.length > 0) {
          this.respostaQuestionario = result[0].opcao.map((opcao) => {
            return this.alternativas.find((alternativa) => {
              if (parseInt(alternativa.id) === parseInt(opcao))
                return alternativa;
            });
          });
        }
      } else {
        this.respostaQuestionario = [];
      }
    },
  },
  computed: {
    respostas: {
      get() {
        return this.$store.getters["pesquisas/getRespostas"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setRespostas", value);
      },
    },
  },
};
</script>

<style lang="scss"></style>
